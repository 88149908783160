<template>
	<div class="page">
		<div class="service_banner law_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/law_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite">{{bannerObj.subtitle || ""}}</div>
			</div>
		</div>
		<div class="block58"></div>
		<!-- 第一模块 -->
		<div class="huaxue_newinfo_conbox" v-if="dataObj&&dataObj.firstModule.title">
			<div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">{{dataObj.firstModule.title}}</div>
				<div class="type_desc fontsize14">{{dataObj.firstModule.englishTitle}}</div>
			</div>
			<div class="block20"></div>
			<div class="content_box w1239 fontsize14">
				<div v-html="dataObj.firstModule.contents"></div>
			</div>
		</div>
		<!-- 第2模块 -->
		<div class="huaxue_newinfo_conbox" v-if="dataObj&&dataObj.secondModule.title">
			<div class="block58"></div>
			<div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">{{dataObj.secondModule.title}}</div>
				<div class="type_desc fontsize14">{{dataObj.secondModule.englishTitle}}</div>
			</div>
			<div class="block20"></div>
			<div class="content_box w1239 fontsize14">
				<div v-html="dataObj.secondModule.contents"></div>
			</div>
		</div>
		<!-- 第3模块 -->
		<div class="huaxue_newinfo_conbox" v-if="dataObj&&dataObj.thirdModule.title">
			<div class="block58"></div>
			<div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">{{dataObj.thirdModule.title}}</div>
				<div class="type_desc fontsize14">{{dataObj.thirdModule.englishTitle}}</div>
			</div>
			<div class="block20"></div>
			<div class="content_box w1239 fontsize14">
				<div v-html="dataObj.thirdModule.contents"></div>
			</div>
		</div>
		<!-- 第4模块 -->
		<div class="huaxue_newinfo_conbox" v-if="dataObj&&dataObj.fourthModule.title">
			<div class="block58"></div>
			<div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">{{dataObj.fourthModule.title}}</div>
				<div class="type_desc fontsize14">{{dataObj.fourthModule.englishTitle}}</div>
			</div>
			<div class="block20"></div>
			<div class="content_box w1239 fontsize14">
				<div v-html="dataObj.fourthModule.contents"></div>
			</div>
		</div>
		<!-- 第5模块 -->
		<div class="huaxue_newinfo_conbox" v-if="dataObj&&dataObj.fifthModule.title">
			<div class="block58"></div>
			<div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">{{dataObj.fifthModule.title}}</div>
				<div class="type_desc fontsize14">{{dataObj.fifthModule.englishTitle}}</div>
			</div>
			<div class="block20"></div>
			<div class="content_box w1239 fontsize14">
				<div v-html="dataObj.fifthModule.contents"></div>
			</div>
		</div>
		<!-- 第6模块 -->
		<div class="huaxue_newinfo_conbox" v-if="dataObj&&dataObj.sixthModule.title">
			<div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">{{dataObj.sixthModule.title}}</div>
				<div class="type_desc fontsize14">{{dataObj.sixthModule.englishTitle}}</div>
			</div>
			<div class="block20"></div>
			<div class="content_box w1239 fontsize14">
				<div v-html="dataObj.sixthModule.contents"></div>
			</div>
		</div>
		
		
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			dataObj:null,
			bannerObj:{}
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(14).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		this.getdataObj()

	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//获取数据
		getdataObj() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 1,
			};
			this.$http.post("frontEnd/law/jigoumingdan", params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						_this.dataObj = dataobj
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
