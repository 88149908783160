import { render, staticRenderFns } from "./lawNameList.vue?vue&type=template&id=402386aa&"
import script from "./lawNameList.vue?vue&type=script&lang=js&"
export * from "./lawNameList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports